// Import google font: Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Colors Variables
$white: #ffffff;
$black: #000000;
$bg1: #0a071e;
$bg2: #0f0836;

$orange: #F79E44;
// $pink: #E71D8B;
$pink: #366cc3;
// $ltpink: #ECBBDA;
$ltpink: #c1bbec;
$purple: #3f3795;
// $purple2: #372562;
$purple2: #252b62;
$ltpurple: #BCB7C3;


// $imagePosters: "https://cdn.shopify.com/s/files/1/0607/5056/1539/articles/netflix.jpg?v=1669877376";
$imagePosters: "https://th.bing.com/th/id/OIG4.gR0DRRblnoCKn7SvAwVl?pid=ImgGn";

// Global default
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: $white;
  background-color: $bg1;
}

// Branding Logo
.nav-logo {
  height: 50px;
  width: 150px;
  object-fit: contain;
}
.footer-logo {
  width: 150px;
  object-fit: contain;
  margin-bottom: 30px;
}

// Custom Bootstrap 
.btn.btn-primary {
  background-color: $pink;
  border-color: $pink;
}
.navbar.bg-dark {
  background-color: $bg1 !important;
}
.btn.btn-outline {
  background-color: transparent;
  border: 1px solid $pink;
}
.breadcrumb, .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: $pink;
}
.breadcrumb .breadcrumb-item.active {
  font-size: 14px;
  color: $white;
}
.breadcrumb .breadcrumb-item::before{
  color: $pink;
}