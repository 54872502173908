
// Soon 
.soon-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  // background: linear-gradient(to bottom, rgba(23, 8, 42, 1) 0%, rgba(23, 8, 42, 0.8) 50%, rgba(23, 8, 42, 1) 100%), url($imagePosters);
  background: linear-gradient(to bottom, rgb(6, 6, 31) 0%, rgba(12, 7, 44, 0.8) 50%, rgb(8, 7, 40) 100%), url($imagePosters);
  background-size: cover;
  background-position: 50% 50%;
}
.soon-logo {
  width: 260px;
  height: 260px;
  object-fit: contain;
}
.soon-text {
  letter-spacing: 4px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 6px;
  border: 1px solid $pink;
  color: $pink;
  margin-top: 20px;
}

// Welcome
.welcome-banner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 800px;
  position: relative;
  // background: linear-gradient(to bottom, rgba(23, 8, 42, 0.8) 0%, rgba(23, 8, 42, 0.6) 50%, rgba(23, 8, 42, 1) 100%), url($imagePosters);
  background: linear-gradient(to bottom, rgba(8, 6, 30, 0.8) 0%, rgba(14, 8, 46, 0.6) 50%, rgb(15, 8, 42) 100%), url($imagePosters);
  background-size: cover;
  background-position: 50% 50%;
}
.welcome-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.welcome-content h1 {
  font-weight: 700;
}
.welcome-content h3 {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}