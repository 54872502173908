// Main
.main-container {
  min-height: 100vh;
  background-color: $bg1;
}
.main-section {
  padding: 60px 0;
}
.main-section img {
  width: 100%;
}
.page-header {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background: linear-gradient(to bottom, rgba(23, 8, 42, 0.8) 0%, rgba(23, 8, 42, 0.8) 50%, rgba(23, 8, 42, 1) 100%), url($imagePosters);
  background-size: cover;
  background-position: 50% 50%;
}
.page-content {
  padding: 60px 0;
}
.page-content h3 {
  margin: 40px 0 20px 0;
  color: $pink;
}
.page-content p, ul li {
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
}
.page-content a {
  color: $pink;
}
.page-content .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
  --bs-accordion-color: $white;
}
.page-content .accordion-item {
  margin-bottom: 10px;
  background-color: $bg2;
  color: $white;
}
.page-content .accordion-header, 
.page-content .accordion-button {
  color: $white;
}
.page-content .accordion-button::after,
.page-content .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.page-content .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: $pink;
}

// Spinner container
.spinner-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Footer
.main-footer {
  padding: 50px 0;
  background-color: $bg1;
}
.main-footer a {
  color: $white;
  line-height: 40px;
  font-weight: 400;
  font-size: 14px;
}
.footer-copy {
  background-color: $bg2;
  padding: 20px 0;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
}
.footer-copy a {
  color: $pink;
  margin: 0 5px;
}